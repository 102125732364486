import * as React from "react";
// import data_school from '../data_school.json';
import TumbonAddr from "../util/TumbonAddr.json";
import { Select } from "antd";
const { Option } = Select;

export default function Filter({
  onChange,
  data,
  optionYear = [],
  optionSungkud = [],
}) {
  const [view, setView] = React.useState(data?.view);
  const [year, setYear] = React.useState(data?.year);
  const [province, setProvince] = React.useState(data?.province);
  const [district, setDistrict] = React.useState(data?.city_name);
  const [subDistrict, setSubDistrict] = React.useState(data?.tumbon_name);
  const [provinceId, setProvinceId] = React.useState(data?.province_name);
  const [districtId, setDistrictId] = React.useState(data?.city_name);
  const [subDistrictId, setSubDistrictId] = React.useState(data?.tumbon_name);
  const [area, setArea] = React.useState(data?.area);
  const [sungkud, setSungkud] = React.useState(data?.sungkud);

  // filter.province

  React.useEffect(() => {
    console.log("province >>", data)
    setProvince(data?.province_name)
    setDistrict(data?.city_name)
    // setSchool(mapData(dataSummaryAllyear, filter))
  }, [data]);

  console.log("province >>", province)
  console.log("district >>", district)
  console.log("data >>", data)

  return (

    <div className="code-box-shape4 queue-anim-demo flex flex-col lg:flex-row" >
      <>
        <div className="mx-2">
          <h5 className="text-sm m-0 ml-[9px]" > ปีการศึกษา: </h5>
          <Select
            className='new-design-select'
            placeholder="ปีการศึกษา"
            showSearch
            style={{ width: 120, margin: 4, fontFamily: "Kanit" }}
            defaultValue="2564"
            optionFilterProp="children"
            value={year}
            onChange={(val) => {
              setYear(val);
              setProvince(null);
              setDistrict(null);
              setSubDistrict(null);
              onChange({
                area: null,
                city_name: null,
                province_name: null,
                sungkud: null,
                tumbon_name: null,
                type: "all",
                view: "country",
                year: `${val}`,
              });
            }}
          >
            {optionYear.map((x) => (
              <Option value={x.value}>{x.text}</Option>
            ))}
          </Select>
        </div>
        <div className="mx-2">
          <h5 className="text-sm m-0 ml-[9px]" >จังหวัด: </h5>
          <Select
            className='new-design-select'
            placeholder="จังหวัด"
            showSearch
            value={province ? province : 'ทั้งหมด'}
            onChange={(val) => {
              if (val === 'ทั้งหมด') {
                setProvince(null);
                setDistrict(null);
                setSubDistrict(null);
                onChange({
                  area: null,
                  city_name: null,
                  province_name: null,
                  sungkud: null,
                  tumbon_name: null,
                  type: "all",
                  view: "country",
                  year: `${year}`,
                });
              } else {
                setProvince(val);
                setDistrict(null);
                setSubDistrict(null);
                setDistrictId(null);
                setSubDistrictId(null);
                onChange({
                  area: null,
                  city_name: null,
                  province_name: val,
                  sungkud: null,
                  tumbon_name: null,
                  type: "all",
                  view: "province",
                  year: `${year}`,
                });
              }

            }}
            style={{ width: 150, margin: 4, fontFamily: "Kanit" }}
            optionFilterProp="children"
          >
            <Option value={'ทั้งหมด'}>{'ทั้งหมด'}</Option>
            {TumbonAddr.filter(
              (value, index, self) =>
                index ===
                self.findIndex((t) => t.provincename === value.provincename)
            ).map((x) => (
              <Option value={x.provincename}>{x.provincename}</Option>
            ))}
          </Select>
        </div>
        <div className="mx-2">
          <h5 className="text-sm m-0 ml-[9px]" >อำเภอ: </h5>
          <Select
            className='new-design-select'
            placeholder="อำเภอ"
            city
            showSearch
            disabled={province === null}
            style={{ width: 150, margin: 4, fontFamily: "Kanit" }}
            optionFilterProp="children"
            value={district ? district : 'ทั้งหมด'}
            onChange={(val) => {
              if (val === 'ทั้งหมด') {
                setProvince(null);
                setDistrict(null);
                setSubDistrict(null);
                onChange({
                  area: null,
                  city_name: null,
                  sungkud: null,
                  tumbon_name: null,
                  type: "all",
                  view: "province",
                  year: `${year}`,
                });
              } else {
                setDistrict(val);
                onChange({
                  city_name: val,
                  view: "city",
                  year: `${year}`,
                });
              }
            }}
          >
            <Option value={'ทั้งหมด'}>{'ทั้งหมด'}</Option>
            {TumbonAddr.filter(
              (x) => province === x.provincename || province === null
            )
              .filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.cityid === value.cityid)
              )
              .map((x) => (
                <Option value={x.cityname}>{x.cityname}</Option>
              ))}
          </Select>
        </div>
        {/* <Select
          placeholder="ตำบล"
          showSearch
          value={subDistrictId}
          disabled={provinceId === null || districtId === null}
          onChange={(val) => {
            let item = TumbonAddr.find((x) => x.tumbonid === val);
            if (item) {
              setProvince(item.provincename);
              setDistrict(item.cityname);
              setSubDistrict(item.tumbonname);
              setProvinceId(item.provinceid);
              setDistrictId(item.cityid);
              setSubDistrictId(item.tumbonid);
              onChange({
                view: "province",
                type: "tumbon",
                year,
                province: item.provincename,
                district: item.cityname,
                subDistrict: item.tumbonname,
              });
            }
          }}
          style={{ width: 150, margin: 4, fontFamily: "Kanit" }}
          optionFilterProp="children"
        >
          {TumbonAddr.filter(
            (x) =>
              (province === x.provincename && x.cityname === district) ||
              district === null
          )
            .filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.tumbonid === value.tumbonid)
            )
            .map((x) => (
              <Option value={x.tumbonname}>{x.tumbonname}</Option>
            ))}
        </Select> */}
      </>

    </div >
  );
}
